import Config from '@/api/config.js'

const state = {
    competitions: [],
    predictionsOpen: false,
    currentGameweek: -1,
    standings: [],
    overallLeagueId: null,
    competitionId: null
};

const getters = {
    getCompetitions: state => state.competitions,
    getCompetitionPredictionsOpen: state => state.predictionsOpen,
    getCurrentGameweek: state => state.currentGameweek,
    getOverallLeagueId: state => state.overallLeagueId,
    getCompetitionId: state => state.competitionId,
    getCurrentStandings: state => state.standings,
    getMunsterRoundRobin: state => { return state.standings.filter(it => it.groupEnt.name == 'Munster Round Robin') },
    getLeinsterRoundRobin: state => { return state.standings.filter(it => it.groupEnt.name == 'Leinster Round Robin') },
    getJoeMcDonaghRoundRobin: state => { return state.standings.filter(it => it.groupEnt.name == 'Joe McDonagh Cup') },
}

const mutations = {
    setCompetitions(state, competitionData){
        state.competitions = competitionData;
    },
    setCompetitionPredictionsOpen(state, open){
        state.predictionsOpen = open;
    },
    setCurrentGameweekForCompetition(state, gameweek){
      state.currentGameweek = gameweek;
    },
    setCompetitionStandings(state, standings){
      state.standings = standings;
    },
    setOverallLeagueId(state, id){
      state.overallLeagueId = id;
    },
    setCompetitionId(state, id){
      state.competitionId = id;
    }
};

const actions = {
  async updateCompetition({ commit }, competitionData) {
    try {
      for(const matchday of competitionData.matchdays){
        let combinedDateTime = matchday.startDateString + 'T' + matchday.startTimeString + ':00';
        matchday.startDate = combinedDateTime; 
        let combinedEndDateTime = matchday.endDateString + 'T' + matchday.endTimeString + ':00';
        matchday.endDate = combinedEndDateTime; 
      }
      const response = await Config.updateCompetition(competitionData);
      console.log(response);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error updating competition:', error);
    }
  },
  async fetchCompetitions({ commit }) {
    try {
      const response = await Config.fetchCompetitions(); // Make API call
      commit('setCompetitions', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchCompetitionPredictionOpen({ commit }, sport) {
    try {
      const response = await Config.fetchCompetitionPredictionsOpen(sport); // Make API call
      commit('setCompetitionPredictionsOpen', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchCompetitionStatus({ commit }, sport) {
    try {
      const response = await Config.fetchCompetitionStatus(sport);
      commit('setCompetitionPredictionsOpen', response.data.predictionsOpen);
      commit('setCurrentGameweekForCompetition', response.data.gameweek);
      commit('setOverallLeagueId', response.data.overallLeagueId);
      commit('setCompetitionId', response.data.competitionId)
    } catch (error) {
      console.error('Error fetching current gameweek:', error);
    }
  },
  async fetchCompetitionStandings({ commit }, competitionId) {
    try {
      const response = await Config.fetchCompetitionStandings(competitionId);
      commit('setCompetitionStandings', response.data);
    } catch (error) {
      console.error('Error fetching current standings:', error);
    }
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
